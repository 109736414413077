import seriesMasterList from "../../classes/seriesMasterList";
import Nav from "../../components/Nav/Nav";
import SeriesList from "../../components/SeriesList/SeriesList";
import "./Home.css";
import { useState } from "react";
import { cloneDeep } from "lodash";
import Papa, { ParseResult } from "papaparse";
import seriesProgress from "../../classes/seriesProgress";
import gameProgress from "../../classes/gameProgress";

const sml = new seriesMasterList();

export default function Home() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [seriesData, setSeriesData] = useState<seriesProgress[]>([]);

  const handleClearFile = () => {
    setSelectedFile(null);
    setSeriesData([]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files?.[0];

    if (file == undefined || file == null) {
      setSelectedFile(null);
    } else {
      setSelectedFile(file);

      if (file) {
        Papa.parse(file, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          transformHeader: function (h) {
            {
              h = h.trim();
              h = h.replace(/\s/g, "");
              h = h.toLowerCase();
              h = h.replace(/['"]+/g, "");
              return h;
            }
          },
          complete: (result: ParseResult<any>) => {
            if (validHeaders(result.data[0])) {
              buildSeriesData(result.data);
            }
          },
        });
      }
    }
  };

  const validHeaders = (gd: any) => {
    return (
      gd["gamename"] &&
      gd["platform"] &&
      gd["gameurl"] &&
      gd["achievementswon(incl.dlc)"] &&
      gd["maxachievements(incl.dlc)"] &&
      gd["gamerscorewon(incl.dlc)"] &&
      gd["maxgamerscore(incl.dlc)"] &&
      gd["mycompletionpercentage"]
    );
  };

  const buildSeriesData = (uploadData: any) => {
    for (let i = 0; i < sml.masterList.length; i++) {
      const seriesGames = sml.masterList[i].validGames;
      let _gamesProgress = [];

      for (let k = 0; k < seriesGames.length; k++) {
        const gameTitles = seriesGames[k];
        let gp = null;

        for (let l = 0; l < gameTitles.length; l++) {
          const _gp = searchSeriesForTitle(uploadData, gameTitles[l]);

          if (gp === null) {
            gp = _gp;
          }

          if (gp != null && _gp.completionStatus) {
            gp = _gp;
            break;
          }
        }

        if (gp != null) _gamesProgress.push(gp);
      }

      let _seriesData = cloneDeep(seriesData);

      _seriesData.push(
        new seriesProgress(
          sml.masterList[i].title,
          sml.masterList[i].flavortext,
          _gamesProgress,
          sml.masterList[i].validPlatformStacks
        )
      );

      setSeriesData((oldArray) => [...oldArray, ..._seriesData]);
    }
  };

  const searchSeriesForTitle = (uploadData: any, title: string) => {
    for (let i = 0; i < uploadData.length; i++) {
      const currGame = uploadData[i];
      if (
        currGame["gamename"] === title &&
        currGame["achievementswon(incl.dlc)"] ===
          currGame["maxachievements(incl.dlc)"] &&
        currGame["gamerscorewon(incl.dlc)"] ===
          currGame["maxgamerscore(incl.dlc)"] &&
        currGame["mycompletionpercentage"] === 100
      ) {
        return new gameProgress(title, true);
      }
    }

    return new gameProgress(title, false);
  };

  return (
    <>
      <Nav />
      <div className="contentWrapper">
        <div className="content">
          <div className="series-info-content">
            <div className="series-help">
              <b>What is this?</b>
            </div>
            <ul>
              <li>
                <div>
                  The Great Valley achievement hunting Discord server has an
                  ongoing contest called 'Series Setlist'. This tool allows you
                  to upload your exported game collection from TA to
                  automatically check you progress on the various series
                  available as part of the contest
                </div>
              </li>
              <li>
                <div>
                  <b>
                    <u>This is still a work in progress and not a completed list of every series</u>
                  </b>
                </div>
              </li>
            </ul>
            <br />
            <div className="series-help">
              <b>
                How do I get my game collection data? (Requires TA Pro
                subscription)
              </b>
            </div>
            <ul>
              <li>
                <div>
                  Go to{" "}
                  <i>
                    https://www.trueachievements.com/gamer/<b>YOUR-GAMERTAG</b>
                    /gamecollection
                  </i>
                </div>
              </li>
              <li>
                <div>
                  Click 'Export' at the top right and a .csv file should
                  automatically download
                </div>
              </li>
              <li>
                <div>
                  Upload the .csv file by clicking the{" "}
                  <b>'Choose File / Browse'</b> button below
                </div>
              </li>
            </ul>
          </div>
          <div>
            {selectedFile === null ? (
              <>
                <h3>Upload Game Collection Data Here</h3>
                <input type="file" onChange={handleFileChange} accept=".csv" />
              </>
            ) : (
              <div>
                <br />
                <button className="clear-btn" onClick={handleClearFile}>
                  Clear
                </button>
                <span>Selected file: {selectedFile.name}</span>
              </div>
            )}
          </div>
          <SeriesList seriesProgress={seriesData} />
        </div>
      </div>
    </>
  );
}
