export default class series {
  title: string;
  flavortext: string;
  validGames: string[][];
  validPlatformStacks: string[][];

  constructor(_title: string, _flavortext: string, _validGames: string[][], _validPlatformStacks: string[][]) {
    this.title = _title;
    this.flavortext = _flavortext;
    this.validGames = _validGames;
    this.validPlatformStacks = _validPlatformStacks;
  }
}
