import './Nav.css';
import { Link } from 'react-router-dom';

export default function Nav() {
  return (
    <div className="nav">
        <div className="siteTitle">
          <b>Series Setlist Checker</b>
        </div>
    </div>
  );
}
