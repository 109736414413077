import gameProgress from "./gameProgress";

export default class seriesProgress {
  title: string;
  flavortext: string;
  gamesProgress: gameProgress[];
  platformStackInfo: string[][];

  constructor(
    _title: string,
    _flavortext: string,
    _gamesProgress: gameProgress[],
    _platformStackInfo: string[][]
  ) {
    this.title = _title;
    this.flavortext = _flavortext;
    this.gamesProgress = _gamesProgress;
    this.platformStackInfo = _platformStackInfo;
  }
}
