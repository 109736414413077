import "./Series.css";
import { Check, X, Info } from "react-feather";
import { useEffect, useState } from "react";
import gameProgress from "../../classes/gameProgress";

interface iSeries {
  seriesTitle: string;
  seriesFlavortext: string;
  gamesProgress: gameProgress[];
  platformStackInfo: string[][];
}

export default function Series({
  seriesTitle,
  seriesFlavortext,
  gamesProgress,
  platformStackInfo,
}: iSeries) {
  const calculateCompleted = (gamesProgress: gameProgress[]) => {
    let total = 0;

    for (let i = 0; i < gamesProgress.length; i++) {
      if (gamesProgress[i].completionStatus) {
        total++;
      }
    }

    return total;
  };

  const [showDetails, setShowDetails] = useState(false);
  const [completed, setCompleted] = useState(calculateCompleted(gamesProgress));
  const [total, setTotal] = useState(gamesProgress.length);

  const toggleDetails = () => {
    setShowDetails((showDetails) => !showDetails);
  };

  const renderCompletedTab = () => {
    if (completed === total) {
      return (
        <div
          className="no-select series-progress series-completed"
          onClick={() => toggleDetails()}
        >
          Completed ({completed}/{total})
        </div>
      );
    } else if (completed > 0) {
      return (
        <div
          className="no-select series-progress series-partial"
          onClick={() => toggleDetails()}
        >
          Completed ({completed}/{total})
        </div>
      );
    } else {
      return (
        <div
          className="no-select series-progress"
          onClick={() => toggleDetails()}
        >
          Completed ({completed}/{total})
        </div>
      );
    }
  };

  return (
    <div className="series">
      <div className="series-info">
        <div>
          <div className="series-title">{seriesTitle}</div>
          <div className="series-flavortext">
            <i>{seriesFlavortext}</i>
          </div>
        </div>
        {renderCompletedTab()}
      </div>
      {showDetails ? (
        <div className="series-details">
          <hr />
          <div className="series-games">
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th className="completed">Completed</th>
                  <th>Game</th>
                  <th className="otherVersions">Other Versions Allowed</th>
                </tr>
              </thead>
              <tbody>
                {gamesProgress.map((game, i) => (
                  <tr>
                    <td>
                      {game.completionStatus ? (
                        <Check
                          className="status-icon"
                          size={25}
                          color="green"
                        />
                      ) : (
                        <X className="status-icon" size={25} color="red" />
                      )}
                    </td>
                    <td>
                      <span className="series-game">{game.title}</span>
                    </td>
                    <td>{platformStackInfo[i]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
}
