import series from "./series";

export default class seriesMasterList {
  masterList: series[] = [
    new series(
      "A Plague Tale",
      "Protector of the Carrier",
      [
        ["A Plague Tale: Innocence", "A Plague Tale: Innocence (Windows)"],
        ["A Plague Tale: Requiem"],
      ],
      [["Windows"], []]
    ),
    new series(
      "Ace Attorney",
      "The Turnabout Terror",
      [
        ["Phoenix Wright: Ace Attorney Trilogy"],
        ["Apollo Justice: Ace Attorney Trilogy"],
        ["Ace Atoorney Investigations Collection"],
      ],
      [[], [], []]
    ),
    new series(
      "Adventure Time",
      "Son of the Comet",
      [
        ["Adevnture Time: The Secret of the Nameless Kingdom"],
        [
          "Adventure Time: Finn and Jake Investigations",
          "Adventure Time: Finn and Jake Investigations (Xbox 360)",
        ],
        ["Adventure Time: Pirates of the Enchiridion"],
      ],
      [[], ["360"], []]
    ),
    new series(
      "AeternoBlade",
      "Paradoxical Warrior",
      [
        ["AeternoBlade"],
        ["AeternoBlade II: Infinity", "AeternoBlade II: Infinity (Xbox One)"],
      ],
      [[], ["One"]]
    ),
    new series(
      "Alien",
      "Sole Human Surivior",
      [
        [
          "Alien: Isolation",
          "Alien: Isolation (Windows)",
          "Alien: Isolation (Xbox 360)",
        ],
        ["Aliens: Fireteam Elite"],
        ["Aliens: Dark Descent"],
      ],
      [["Windows, 360"], [], []]
    ),
    new series(
      "All Your Bricks Are Belong To Us",
      "Rule Breaker",
      [
        ["LEGO Dimensions", "LEGO Batman"],
        ["LEGO Batman", "LEGO Batman (JP)"],
        ["LEGO Batman 2: DC Super Heroes"],
        ["LEGO The Lord of the Rings"],
        ["The LEGO Movie Videogame", "The LEGO Movie Videogame (Xbox 360)"],
        ["LEGO The Hobbit", "LEGO The Hobbit (Xbox 360)"],
        [
          "LEGO Batman 3: Beyond Gotham",
          "LEGO Batman 3: Beyond Gotham (Xbox 360)",
        ],
        ["LEGO DC Super-Villains"],
        ["The LEGO Movie 2 Videogame"],
        ["Portal: Still Alive"],
        ["Portal 2"],
        ["Doctor Who: The Lonely Assassins"],
        ["Doctor Who: The Edge of Reality"],
        ["Ghostbusters: The Sanctum of Slime"],
        ["Ghostbusters"],
        [
          "Ghostbusters: The Video Game Remastered",
          "Ghostbusters: The Video Game",
        ],
        ["Ghostbusters: Spirits Unleashed"],
        ["Midway Arcade Origins"],
        ["Adevnture Time: The Secret of the Nameless Kingdom"],
        [
          "Adventure Time: Finn and Jake Investigations",
          "Adventure Time: Finn and Jake Investigations (Xbox 360)",
        ],
        ["Adventure Time: Pirates of the Enchiridion"],
        ["Sonic Unleashed"],
        ["Sonic & All-Stars Racing Transformed"],
        ["Team Sonic Racing"],
        ["Sonic Mania"],
        ["Sonic Forces"],
        ["Sonic Colors: Ulimate"],
        ["Sonic Origins"],
        ["Sonic Frontiers"],
        ["Sonic Superstars"],
      ],
      [
        ["360"],
        ["JP"],
        [],
        [],
        ["360"],
        ["360"],
        ["360"],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["360"],
        [],
        [],
        [],
        ["360"],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "Amnesia",
      "Descended From Darkness",
      [
        ["Amnesia: Collection", "Amnesia: Collection (Windows)"],
        ["Amnesia: Rebirth"],
        ["Amnesia: The Bunker"],
      ],
      [["Windows"], [], []]
    ),
    new series(
      "Aragami",
      "Spirit of Vengeance",
      [["Aragami: Shadow Edition"], ["Aragami 2"]],
      [[], []]
    ),
    new series(
      "ARK",
      "Survivor Evolved",
      [["ARK: Survival Evolved"], ["ARK: Survival Ascended"], ["PixARK"]],
      [[], [], []]
    ),
    new series(
      "Arkane Connected Universe",
      "The Outsider",
      [
        [
          "Dishonored Definitive Edition",
          "Dishonored Definitive Edition (Windows)",
          "Dishonored",
        ],
        ["Dishonored 2", "Dishonored 2 (Windows)"],
        [
          "Dishonored: Death of the Outsider",
          "Dishonored: Death of the Outsider (Windows)",
        ],
      ],
      [["Windows", "360"], ["Windows"], ["Windows"]]
    ),
    new series(
      "Arkanoid",
      "Cerificate of DOH",
      [["ARKANOID Live!"], ["Arkanoid Eternal Battle"]],
      [[], []]
    ),
    new series(
      "Asdivine",
      "Jack of all Stats",
      [
        ["Asdivine Hearts"],
        ["Asdivine Hearts II"],
        ["Asdivine Dios"],
        ["Asdivine Menace"],
        ["Asdivine Kamura"],
        ["Asdivine Cross"],
        ["Asdivine Saga"],
      ],
      [[], [], [], [], [], [], []]
    ),
    new series(
      "Assassin's Creed",
      "Mentor to Assassins",
      [
        ["Assassin's Creed"],
        ["Assassin's Creed The Ezio Collection"],
        ["Assassin's Creed II"],
        ["Assassin's Creed: Brotherhood"],
        ["Assassin's Creed: Revelations"],
        ["Assassin's Creed III", "Assassin's Creed III Remastered"],
        ["Assassin's Creed Liberation HD"],
        [
          "Assassin's Creed IV: Black Flag",
          "Assassin's Creed IV: Black Flag (Xbox 360)",
        ],
        ["Assassin's Creed Rogue", "Assassin's Creed Rogue Remastered"],
        ["Assassin's Creed Syndicate"],
        ["Assassin's Creed Origins"],
        ["Assassin's Creed Valhalla"],
        ["Assassin's Creed Mirage"],
        ["Assassin's Creed Chronicles: China"],
        ["Assassin's Creed Chronicles: India"],
        ["Assassin's Creed Chronicles: Russia"],
        ["Discovery Tour: Viking Age"],
      ],
      [
        [],
        [],
        [],
        [],
        [],
        ["360"],
        ["360"],
        ["360"],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "Assassin's Creed (Desmond)",
      "Master Assassin",
      [
        ["Assassin's Creed"],
        ["Assassin's Creed The Ezio Collection"],
        ["Assassin's Creed II"],
        ["Assassin's Creed: Brotherhood"],
        ["Assassin's Creed: Revelations"],
        ["Assassin's Creed III", "Assassin's Creed III Remastered"],
        ["Assassin's Creed Liberation HD"],
      ],
      [[], [], [], [], [], ["360"], ["360"]]
    ),
    new series(
      "Assassin's Creed (Layla)",
      "Master Hidden One",
      [
        ["Assassin's Creed Origins"],
        ["Assassin's Creed Odyssey"],
        ["Assassin's Creed Valhalla"],
        ["Assassin's Creed Mirage"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Assassin's Creed Chronicles",
      "Master Of Shadows",
      [
        ["Assassin's Creed Chronicles: China"],
        ["Assassin's Creed Chronicles: India"],
        ["Assassin's Creed Chronicles: Russia"],
      ],
      [[], [], []]
    ),
    new series(
      "Asterix And Obelix",
      "Legendary Gaulish Warrior",
      [
        ["Asterix & Obelix XXL: Romastered"],
        ["Asterix & Obelix XXL 2"],
        ["Asterix & Obelix XXL3: The Crystal Menhir"],
        ["Asterix & Obelix XXXL: The Ram From Hibernia"],
        ["Asterix & Obelix: Heroes"],
        ["Asterix & Obelix: Slap Them All!"],
        ["Asterix & Obelix: Slap Them All! 2"],
      ],
      [[], [], [], [], [], [], []]
    ),
    new series(
      "Atari",
      "Have You Played Today?",
      [
        ["Atari Flashback Classics Vol. 1"],
        ["Atari Flashback Classics Vol. 2"],
        ["Atari Flashback Classics Vol. 3"],
        ["Asteroids: Recharged"],
        ["Berzerk: Recharged"],
        ["Black Widow: Recharged"],
        ["Breakout: Recharged"],
        ["Caverns of Mars: Recharged"],
        ["Centipede: Recharged"],
        ["Gravitar: Recharged"],
        ["Missile Command: Recharged"],
        ["Quantum: Recharged"],
        ["Yars: Recharged"],
      ],
      [[], [], [], [], [], [], [], [], [], [], [], [], []]
    ),
    new series(
      "Atari Flashback",
      "Elf the Exterminator",
      [
        ["Atari Flashback Classics Vol. 1"],
        ["Atari Flashback Classics Vol. 2"],
        ["Atari Flashback Classics Vol. 3"],
      ],
      [[], [], []]
    ),
    new series(
      "Atari Recharted",
      "Red Sunset",
      [
        ["Asteroids: Recharged"],
        ["Berzerk: Recharged"],
        ["Black Widow: Recharged"],
        ["Breakout: Recharged"],
        ["Caverns of Mars: Recharged"],
        ["Centipede: Recharged"],
        ["Gravitar: Recharged"],
        ["Missile Command: Recharged"],
        ["Quantum: Recharged"],
        ["Yars: Recharged"],
      ],
      [[], [], [], [], [], [], [], [], [], []]
    ),
    new series(
      "Attack on Titan",
      "Devil of all Earth",
      [["Attack on Titan"], ["Attack on Titan 2"]],
      [[], []]
    ),
    new series(
      "Avatar",
      "Fully Realized Avatar",
      [
        ["Avatar: The Last Airbender - The Burning Earth"],
        ["Avatar: The Last Airbender - Quest for Balance"],
      ],
      [[], []]
    ),
    new series(
      "Battlefield",
      "Weapon of Mass Destruction",
      [
        ["Battlefield 4", "Battlefield 4 (Xbox 360)"],
        ["Battlefield 1"],
        ["Battlefield V"],
        ["Battlefield 2042", "Battlefield 2042 (Xbox One)"],
      ],
      [["360"], [], [], ["One"]]
    ),
    new series(
      "Battlefield (Great War Era)",
      "Through Mud and Blood",
      [["Battlefield 1"], ["Battlefield V"]],
      [[], []]
    ),
    new series(
      "Battlefield (Modern Era)",
      "Semper Fidelis",
      [
        ["Battlefield 4", "Battlefield 4 (Xbox 360)"],
        ["Battlefield 2042", "Battlefield 2042 (Xbox One)"],
      ],
      [["360"], ["One"]]
    ),
    new series(
      "Behemoth Universe",
      "We Come In Peace",
      [
        ["Castle Crashers Remastered"],
        ["Pit People"],
        ["Alien Hominid HD"],
        ["Alien Hominid Invasion"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Beholder",
      "Beholden to None",
      [["Beholder Complete Edition"], ["Beholder 2"], ["Beholder 3"]],
      [[], [], []]
    ),
    new series(
      "Ben 10",
      "The Cosmic Savior",
      [["Ben 10"], ["Ben 10: Power Trip"]],
      [[], []]
    ),
    new series(
      "Bendy",
      "Little Devil Darlin'",
      [["Bendy and the Ink Machine"], ["Bendy and the Dark Arrival"]],
      [[], []]
    ),
    new series(
      "BioShock",
      "Songbird of Rapture",
      [
        [
          "BioShock Remastered",
          "BioShock",
          "BioShock (DE)",
          "BioShock (JP)",
          "BioShock (KR)",
        ],
        [
          "BioShock 2 Remastered",
          "BioShock 2",
          "BioShock 2 (JP)",
          "BioShock 2 (KR)",
          "BioShock 2 (GFWL)",
          "BioShock 2 (RU) (GFWL)",
        ],
        ["BioShock Infinite", "BioShock Infinite: The Complete Edition"],
      ],
      [
        ["360", "JP", "KR", "DE"],
        ["360", "360 JP", "GFWL", "RU GFWL", "KR"],
        ["360"],
      ]
    ),
    new series(
      "Blasphemous",
      "Warrior of the Silent Sorrow",
      [["Blasphemous"], ["Blasphemous 2"]],
      [[], []]
    ),
    new series(
      "Blaster Master Zero",
      "Blaster 'Master' Zero",
      [
        ["Blaster Master Zero"],
        ["Blaster Master Zero 2"],
        ["Blaster Master Zero 3"],
      ],
      [[], [], []]
    ),
    new series(
      "Bleed",
      "Best Hero of All Time",
      [["Bleed"], ["Bleed 2"]],
      [[], []]
    ),
    new series(
      "Borderlands",
      "True Ultimate Vault Hunter",
      [
        [
          "Borderlands",
          "Borderlands (JP)",
          "Borderlands: Game of the Year Edition",
        ],
        ["Borderlands 2 (Xbox 360)", "Borderlands 2"],
        ["Tiny Tina's Assault on Dragon Keep"],
        [
          "Borderlands: The Pre-Sequel (Xbox 360)",
          "Borderlands: The Pre-Sequel",
        ],
        ["Tales from the Borderlands (Xbox 360)", "Tales from the Borderlands"],
        ["Borderlands 3"],
        ["Tiny Tina's Wonderlands"],
        ["New Tales from the Borderlands"],
      ],
      [["360 JP", "GOTY Edition"], ["360"], [], ["360"], ["360"], [], [], []]
    ),
    new series(
      "Borderlands (Mainline)",
      "A Bigger Number Than You",
      [
        [
          "Borderlands",
          "Borderlands (JP)",
          "Borderlands: Game of the Year Edition",
        ],
        ["Borderlands 2 (Xbox 360)", "Borderlands 2"],
        [
          "Borderlands: The Pre-Sequel (Xbox 360)",
          "Borderlands: The Pre-Sequel",
        ],
        ["Borderlands 3"],
      ],
      [["360 JP", "GOTY Edition"], ["360"], ["360"], []]
    ),
    new series(
      "Borderlands",
      "True Ultimate Vault Hunter",
      [
        ["Tales from the Borderlands (Xbox 360)", "Tales from the Borderlands"],
        ["New Tales from the Borderlands"],
      ],
      [["360"], []]
    ),
    new series(
      "Wonderlands",
      "I'm a Little Teapot",
      [["Tiny Tina's Assault on Dragon Keep"], ["Tiny Tina's Wonderlands"]],
      [[], []]
    ),
    new series(
      "Boss Rush",
      "The One You See Coming",
      [["Cuphead"], ["Eldest Souls"], ["Furi"], ["Godstrike"]],
      [[], [], [], []]
    ),
    new series(
      "Bossa Studio's I Am",
      "I Am Iron Bread",
      [["I Am Bread"], ["I Am Fish"]],
      [[], []]
    ),
    new series("Brawlhalla", "Chosen Legend", [["Brawlhalla"]], [[]]),
    new series(
      "Bullet Heaven",
      "The Red Death",
      [
        ["Army of Ruin"],
        ["Brotato"],
        ["CyberHeroes Arena DX"],
        ["Geometry Survivor"],
        [
          "Hero Survival",
          "Hero Survival (Xbox One)",
          "Hero Survival (Windows)",
        ],
        ["Nerd Survivors"],
        ["Primal Survivors", "Primal Survivors (Windows)"],
        ["REMEDIUM: Sentinels"],
        ["Sky Survivors", "Sky Survivors (Windows)"],
        ["Space Mercenary Defense Force"],
        ["Spirit Hunters: Infinite Horder"],
        ["Vampire Survivors"],
        ["Whispike Survivors: Sword of the Necromancer"],
      ],
      [
        [],
        [],
        [],
        [],
        ["One", "Windows"],
        [],
        ["Windows"],
        [],
        ["Windows"],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "Call of Duty",
      "Mile High Club",
      [
        ["Call of Duty 2"],
        ["Call of Duty 3"],
        [
          "Call of Duty 4: Modern Warfare",
          "Call of Duty: Modern Warfare Remastered",
          "Call of Duty: Modern Warfare Remastered (Windows)",
        ],
        ["Call of Duty: World at War"],
        [
          "Call of Duty: Modern Warfare 2 Campaign Remastered",
          "Call of Duty: Modern Warfare 2",
        ],
        ["Call of Duty: Black Ops"],
        ["Call of Duty: Modern Warfare 3"],
        ["Call of Duty: Black Ops II"],
        ["Call of Duty: Ghosts", "Call of Duty: Ghosts (Xbox 360)"],
        [
          "Call of Duty: Advanced Warfare",
          "Call of Duty: Advanced Warfare (Xbox 360)",
        ],
        [
          "Call of Duty: Black Ops III",
          "Call of Duty: Black Ops III (Xbox 360)",
        ],
        [
          "Call of Duty: Infinite Warfare",
          "Call of Duty: Infinite Warfare (Windows)",
        ],
        ["Call of Duty: WWII"],
        ["Call of Duty: Black Ops 4"],
        ["Call of Duty: Modern Warfare"],
        ["Call of Duty: Black Ops Cold War"],
        ["Call of Duty: Vanguard"],
        ["Call of Duty"],
      ],
      [
        [],
        [],
        ["360", "Windows"],
        [],
        ["360"],
        [],
        [],
        [],
        ["360"],
        ["360"],
        ["360"],
        ["Windows"],
        [],
        [],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "Call of Duty: Black Ops",
      "...And I Will Have My Revenge",
      [
        ["Call of Duty: World at War"],
        ["Call of Duty: Black Ops"],
        ["Call of Duty: Black Ops II"],
        [
          "Call of Duty: Black Ops III",
          "Call of Duty: Black Ops III (Xbox 360)",
        ],
        ["Call of Duty: Black Ops 4"],
        ["Call of Duty: Black Ops Cold War"],
      ],
      [[], [], [], ["360"], [], []]
    ),
    new series(
      "Call of Duty: Modern Warfare",
      "I'm the Juggernaut...",
      [
        [
          "Call of Duty 4: Modern Warfare",
          "Call of Duty: Modern Warfare Remastered",
          "Call of Duty: Modern Warfare Remastered (Windows)",
        ],
        [
          "Call of Duty: Modern Warfare 2 Campaign Remastered",
          "Call of Duty: Modern Warfare 2",
        ],
        ["Call of Duty: Modern Warfare 3"],
        ["Call of Duty: Modern Warfare"],
      ],
      [["360", "Windows"], [], [], []]
    ),
    new series("Call of Duty", "Personal Decorator", [["Call of Duty"]], [[]]),
    new series(
      "Castlevania + Bloodstained",
      "Bloddstained Belmont",
      [
        ["Castlevania Anniversary Collection"],
        ["Castlevania Advance Collection"],
        ["Castlevania Domiinus Collection"],
        [
          "Castlevania: Symphony of the Night",
          "Castlevania: Symphony of the Night (JP)",
        ],
        ["Castlevania: Harmony of Despair"],
        ["Castlevania: Lords of Shadow"],
        ["Castlevania: Lords of Shadow - Mirror of Fate HD"],
        ["Castlevania: Lords of Shadow 2"],
        ["Bloodstained: Curse of the Moon"],
        ["Bloodstained: Curse of the Moon 2"],
        [
          "Bloodstained: Ritual of the Night",
          "Bloodstained: Ritual of the Night (Windows)",
        ],
      ],
      [[], [], [], ["JP"], [], [], [], [], [], [], ["windows"]]
    ),
    new series(
      "Bloodstained",
      "Demon Killer",
      [
        ["Bloodstained: Curse of the Moon"],
        ["Bloodstained: Curse of the Moon 2"],
        [
          "Bloodstained: Ritual of the Night",
          "Bloodstained: Ritual of the Night (Windows)",
        ],
      ],
      [[], [], ["Windows"]]
    ),
    new series(
      "Castlevania",
      "Vampire Killer",
      [
        ["Castlevania Anniversary Collection"],
        ["Castlevania Advance Collection"],
        ["Castlevania Domiinus Collection"],
        [
          "Castlevania: Symphony of the Night",
          "Castlevania: Symphony of the Night (JP)",
        ],
        ["Castlevania: Harmony of Despair"],
        ["Castlevania: Lords of Shadow"],
        ["Castlevania: Lords of Shadow - Mirror of Fate HD"],
        ["Castlevania: Lords of Shadow 2"],
      ],
      [[], [], [], ["JP"], [], [], [], []]
    ),
    new series(
      "Cat Quest",
      "Pawlatinum Meowster",
      [["Cat Quest II"], ["Cat Quest III"]],
      [[], []]
    ),
    new series(
      "Century: Age of Ashes",
      "Born of the Sea of Flame",
      [["Century: Ash"]],
      [[]]
    ),
    new series(
      "Civilization",
      "That We May Live In Peace",
      [
        [
          "Sid Meier's Civilization Revolution",
          "Sid Meier's Civilization Revolution (JP)",
        ],
        ["Sid Meier's Civilization VI"],
      ],
      [[], []]
    ),
    new series(
      "Coffee Talk",
      "Barista Guru",
      [["Coffee Talk"], ["Coffee Talk Episode 2: Hibiscus and Butterfly"]],
      [[], []]
    ),
    new series(
      "Conan",
      "By This Axe I Rule!",
      [["Conan"], ["Conan Exiles"], ["Conan Chop Chop"]],
      [[], [], []]
    ),
    new series(
      "Contra",
      "Headbanded Hero",
      [
        ["Contra Anniversary Collection"],
        ["CONTRA: ROGUE CORPS"],
        ["Contra: Operation Galuga"],
      ],
      [[], [], []]
    ),
    new series(
      "Cook, Serve, Delicious!",
      "Stress Chef Supreme",
      [
        ["Cook, Serve, Delicious!"],
        ["Cook, Serve, Delicious! 2!!"],
        ["Cook, Serve, Delicious! 3?!"],
      ],
      [[], [], []]
    ),
    new series(
      "Co-Optimus",
      "CO-LLA-BO-RATION!",
      [
        ["A Way Out"],
        ["Borderlands 2", "Borderlands 2 (Xbox 360)"],
        ["Castle Crashers Remastered"],
        ["Cuphead"],
        ["Embr"],
        ["Haven"],
        ["Human Fall Flat"],
        ["It Takes Two"],
        ["Lovers in a Dangerous Spacetime"],
        ["Nobody Save the World"],
        ["Moving Out", "Moving Out (Windows)"],
        ["Overcooked! 2", "Overcooked! 2 (Windows)"],
        ["PHOGS!"],
        ["Roboquest"],
        ["Ship of Fools"],
        ["Stardew Valley"],
      ],
      [
        [],
        ["360"],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["Windows"],
        ["Windows"],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "Copyright Striked",
      "Gotta Catch 'Em All",
      [
        ["Cassette Beasts"],
        ["Monster Harvest"],
        ["Monster Sanctuary", "Monster Sanctuary (Windows)"],
        ["Nexomon"],
        ["Nexomon: Extinction"],
        ["Ooblets"],
        ["Palworld"],
        ["Temtem"],
      ],
      [[], [], ["Windows"], [], [], [], [], []]
    ),
    new series(
      "Costume Quest",
      "Mask-O'-Raider",
      [["Costume Quest"], ["Costume Quest 2", "Costume Quest 2 (Xbox 360)"]],
      [[], ["360"]]
    ),
    new series(
      "Crackdown",
      "Key of the City",
      [
        ["Crackdown"],
        ["Crackdown 2"],
        ["Crackdown 3: Campaign"],
        ["Crackdown 3: Wrecking Zone"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Crash + Spyro",
      "N. Sane Dragon",
      [
        ["Crash Bandicoot N. Sane Trilogy"],
        ["Crash Bandicoot 4: It's About Time"],
        ["Crash Team Racing Nitro-Fueled"],
        ["Crash Team Rumble"],
        ["Spyro Reignited Trilogy"],
      ],
      [[], [], [], [], []]
    ),
    new series(
      "Crash Bandicoot",
      "Blunder from Down Under",
      [
        ["Crash Bandicoot N. Sane Trilogy"],
        ["Crash Bandicoot 4: It's About Time"],
        ["Crash Team Racing Nitro-Fueled"],
        ["Crash Team Rumble"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Spyro",
      "Title of the Dragon",
      [["Spyro Reignited Trilogy"]],
      []
    ),
    new series(
      "Crysis",
      "They Call Me Prophet",
      [
        ["Crysis Remastered", "Crysis"],
        ["Crysis 2 Remastered", "Crysis 2"],
        ["Crysis 3 Remastered", "Crysis 3"],
      ],
      [["360"], ["360"], ["360"]]
    ),
    new series(
      "Culmination of Horror",
      "Prey to Me",
      [
        ["Dead by Daylight", "Dead by Daylight (Windows)"],
        ["The Casting of Frank Stone"],
        [
          "The Texas Chainsaw Massacre",
          "The Texas Chainsaw Massacre (Windows)",
        ],
        ["Left 4 Dead"],
        ["Left 4 Dead 2"],
        ["Evil Dead: The Game"],
        ["Silent Hill HD Collection"],
        ["Silent Hill Homecoming"],
        ["Silent Hill: Downpour"],
        ["Resident Evil 0"],
        ["Resident Evil"],
        ["Resident Evil 2", "Resident Evil 2: Z Version"],
        ["Resident Evil Code: Veronica X"],
        ["Resident Evil 3", "Resident Evil 3: Z Version"],
        ["Resident Evil 4"],
        ["Resident Evil Revelations", "Resident Evil Revelations (Xbox 360)"],
        [
          "Resident Evil 5",
          "Resident Evil 5 (Xbox 360)",
          "Resident Evil 5 (GFWL)",
        ],
        [
          "Resident Evil Revelations 2",
          "Resident Evil Revelations 2 (Xbox 360)",
        ],
        ["Resident Evil 6", "Resident Evil 6 (Xbox 360)"],
        [
          "Resident Evil 7: Biohazard",
          "Resident Evil 7: Biohazard Grotesque Ver.",
        ],
        ["Resident Evil Village", "Resident Evil Village: Z Version"],
        [
          "Resident Evil: Operation Raccoon City",
          "Resident Evil: Operation Raccoon City (GFWL)",
        ],
        ["Resident Evil Resistance"],
        ["Resident Evil Re:Verse"],
        [
          "Alien: Isolation",
          "Alien: Isolation (Xbox 360)",
          "Alien: Isolation (Windows)",
        ],
        ["Aliens: Fireteam Elite"],
        ["Aliens: Dark Descent"],
        ["Alan Wake Remastered", "Alan Wake"],
        ["Alan Wake's American Nightmare"],
        ["Alan Wake 2"],
        ["Tomb Raider: Defitive Edition", "Tomb Raider"],
        [
          "Rise of the Tomb Raider",
          "Rise of the Tomb Raider (Xbox 360)",
          "Rise of the Tomb Raider (Windows)",
        ],
        ["Shadow of the Tomb Raider"],
      ],
      [
        ["Windows"],
        [],
        ["Windows"],
        [],
        [],
        [],
        [],
        [],
        [],
        ["360"],
        ["360"],
        ["Z Version"],
        [],
        ["Z Version"],
        [],
        ["360"],
        ["360, GFWL"],
        ["360"],
        ["360"],
        ["Grotesque Version"],
        ["Z Version"],
        ["GFWL"],
        [],
        [],
        ["360, Windows"],
        [],
        [],
        ["360"],
        [],
        [],
        ["360"],
        ["360, Windows"],
        [],
      ]
    ),
    new series(
      "Cult of Purr-sonality",
      "If I Fits, I Sits",
      [
        ["Calico"],
        ["Cat Quest II"],
        ["Cat Quest III"],
        ["Catlateral Damage: Remeowstered"],
        ["Cats and the Other Lives"],
        ["Crypt Custodian"],
        ["Gato Roboto"],
        ["Gori: Cuddly Carnage"],
        ["Little Kitty, Big City"],
        ["Night in the Woods"],
        ["Rain World"],
        ["Schrödinger's Cat and the Raiders of the Lost Quark"],
        ["Stray"],
      ],
      [[], [], [], [], [], [], [], [], [], [], [], [], []]
    ),
    new series(
      "Danganronpa",
      "Desire for Despair",
      [
        ["Danganronpa: Trigger Happy Havoc Anniversary Edition"],
        ["Danganronpa 2: Goodbye Despair Anniversary Edition"],
        ["Danganronpa 3: Killing Harmony Anniversary Edition"],
      ],
      [[], [], []]
    ),
    new series(
      "Dark Pictures Anthology",
      "The Curator of Stories",
      [
        [
          "The Dark Pictures Anthology: Man of Medan",
          "The Dark Pictures Anthology: Man of Medan (Windows)",
        ],
        ["The Dark Pictures Anthology: Little Hope"],
        ["The Dark Pictures Anthology: House of Ashes"],
        ["The Dark Pictures Anthology: The Devil in Me"],
      ],
      [["Windows"], [], [], []]
    ),
    new series(
      "Dark Souls",
      "The Soul of Cinder",
      [
        ["Dark Souls: Remastered", "Dark Souls", "Dark Souls (GFWL)"],
        ["Dark Souls II: Scholar of the First Sin", "Dark Souls II"],
        ["Dark Souls III"],
        ["Sekiro: Shadows Die Twice"],
        ["Elden Ring"],
      ],
      [["360, GFWL"], [], [], [], []]
    ),
    new series(
      "Darkest Dungeon",
      "Come Unto Your Maker",
      [
        ["Darkest Dungeon", "Darkest Dungeon (Windows)"],
        ["Darkest Dungeon II"],
      ],
      [["Windows"], []]
    ),
    new series(
      "Darksiders",
      "Harbinger of Doom",
      [
        ["Darksiders Warmastered Edition", "Darksiders", "Darksiders (JP)"],
        [
          "Darksiders II Deathinitive Edition",
          "Darksiders II",
          "Darksiders II (JP)",
        ],
        ["Darksiders III", "Darksiders III (Windows)"],
        ["Darksiders Genesis", "Darksiders Genesis (Windows)"],
      ],
      [["360, JP"], ["360, JP"], ["Windows"], ["Windows"]]
    ),
    new series(
      "Darwin Project",
      "I Do It for the Fans",
      [["Darwin Project"]],
      [[]]
    ),
    new series("Dauntless", "Behemoth's Bane", [["Dauntless"]], [[]]),
    new series(
      "DC Comics",
      "The Dark Knight",
      [
        [
          "Batman: Arkham Asylum",
          "Batman: Arkham Asylum GOTY",
          "Batman: Arkham Asylum GOTY (GFWL)",
          "Batman: Arkham Asylum (JP)",
          "Batman: Arkham Asylum (GFWL)",
        ],
        ["Batman: Arkham City", "Batman: Arkham City (GFWL)"],
        ["Batman Arkham Knight"],
        ["suicide Squad: Kill the Justice League"],
        ["Gothman Knights"],
        ["DC Universe Online"],
        ["Injustice"],
        ["Injustice 2", "Injustice 2 (Windows)"],
        [
          "Batman: The Telltale Series",
          "Batman: The Telltale Series (Xbox 360)",
          "Batman: The Telltale Series (Windows)",
        ],
        [
          "Batman: The Enemy Within - The Telltale Series",
          "Batman: The Enemy Within - The Telltale Series (Windows)",
        ],
        ["LEGO Batman", "LEGO Batman (JP)"],
        ["LEGO Batman 2: DC Super Heroes"],
        [
          "LEGO Batman 3: Beyond Gotham",
          "LEGO Batman 3: Beyond Gotham (Xbox 360)",
        ],
        ["LEGO DC Super-Villians"],
      ],
      [
        ["JP, GFWL, GOTY, GOTY (GFWL)"],
        ["360, GFWL"],
        [],
        [],
        [],
        [],
        ["Windows"],
        ["360, Windows"],
        ["Windows"],
        ["JP"],
        [],
        ["360"],
      ]
    ),
    new series(
      "Batman: Arkham",
      "Knightfall",
      [
        [
          "Batman: Arkham Asylum",
          "Batman: Arkham Asylum GOTY",
          "Batman: Arkham Asylum GOTY (GFWL)",
          "Batman: Arkham Asylum (JP)",
          "Batman: Arkham Asylum (GFWL)",
        ],
        ["Batman: Arkham City", "Batman: Arkham City (GFWL)"],
        ["Batman Arkham Knight"],
        ["suicide Squad: Kill the Justice League"],
      ],
      [["JP, GFWL, GOTY, GOTY (GFWL)"], ["360, GFWL"], [], []]
    ),
    new series(
      "DC Universe Online",
      "Champion of Earth",
      [["DC Universe Online"]],
      [[]]
    ),
    new series(
      "Injustice",
      "Rage Consumes Me",
      [["Injustice 2"], ["Injustice 2 (Windows)"]],
      [[], ["Windows"]]
    ),
    new series(
      "Telltale's Batman",
      "Fractured Mask",
      [
        [
          "Batman: The Telltale Series",
          "Batman: The Telltale Series (Xbox 360)",
          "Batman: The Telltale Series (Windows)",
        ],
        [
          "Batman: The Enemy Within - The Telltale Series",
          "Batman: The Enemy Within - The Telltale Series (Windows)",
        ],
      ],
      [["360, Windows"], ["Windows"]]
    ),
    new series(
      "de Blob",
      "Paragon of Free Expression",
      [["de Blob"], ["de Blob 2"]],
      [[], ["360"]]
    ),
    new series(
      "Dead by Daylight",
      "The Iridescent One",
      [
        ["Dead by Daylight", "Dead by Daylight (Windows)"],
        ["The Casting of Frank Stone"],
      ],
      [["Windows"], []]
    ),
    new series(
      "Dead Island",
      "Butcher of Banoi",
      [
        [
          "Dead Island Definitive Edition",
          "Dead Island Definitive Edition (JP)",
        ],
        [
          "Dead Island: Riptide Definitive Edition",
          "Dead Island: Riptide Defintive Edition (JP)",
        ],
        ["Escape Dead Island"],
        ["Dead Island Retro Revenge"],
        ["Dead Island 2"],
      ],
      [["JP"], ["JP"], [], [], []]
    ),
    new series(
      "Dead or Alive",
      "Kunichi of Destiny",
      [
        ["Dead or Alive 5 Last Round", "Dead or Alive 5 Last Round (Xbox 360)"],
        ["Dead or Alive 6"],
      ],
      [["360"], []]
    ),
    new series(
      "Dead Rising",
      "7 Day Survivor",
      [
        ["Dead Rising", "Dead Rising (Xbox 360)"],
        [
          "Dead Rising 2: Case Zero",
          "Dead Rising 2: Case Zero (JP)",
          "Dead Rising 2: Case Zero (KR)",
        ],
        ["Dead Rising 2", "Dead Rising 2 (Xbox 360)", "Dead Rising 2 (GFWL)"],
        [
          "Dead Rising 2: Case West",
          "Dead Rising 2: Case West (JP)",
          "Dead Rising 2: Case West (KR)",
        ],
        [
          "Dead Rising 2: Off The Record",
          "Dead Rising 2: Off The Record (Xbox 360)",
          "Dead Rising 2: Off The Record (GFWL)",
        ],
        ["Dead Rising 3"],
        ["Dead Rising 4", "Dead Rising 4 (Windows)"],
      ],
      [
        ["360"],
        ["JP, KR"],
        ["360, GFWL"],
        ["JP, KR"],
        ["360, GFWL"],
        [],
        ["Windows"],
      ]
    ),
    new series(
      "Dead Space",
      "Epic Tier 4 Engineer",
      [
        ["Dead Space (2008)"],
        ["Dead Space: Ignition"],
        ["Dead Space 2"],
        ["Dead Space 3"],
        ["Dead Space"],
      ],
      [[], [], [], [], []]
    ),
    new series(
      "Dead Space (OG Timeline)",
      "Ghost of the Past",
      [
        ["Dead Space (2008)"],
        ["Dead Space: Ignition"],
        ["Dead Space 2"],
        ["Dead Space 3"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Deathless Setlist",
      "No Life",
      [
        [
          "Alien: Isolation",
          "Alien: Isolation (Xbox 360)",
          "Alien: Isolation (Windows)",
        ],
        ["Dead Space 2"],
        ["Dead Space 3"],
        ["LIMBO", "LIMBO (Xbox 360)", "LIMBO (Windows)"],
        [
          "Ori and the Blind Forest: Definitive Edition",
          "Ori and the Blind Forest",
          "Ori and the Blind Forest (Nintendo Switch)",
        ],
        ["Ori and the Will of the Wisps"],
        ["Outlast"],
        ["Outlast 2"],
        ["Shadow Warrior"],
        ["Sheepo"],
        ["SUPERHOT", "SUPERHOT (Windows)", "SUPERHOT VR (Windows)"],
        ["Unravel Two"],
        ["Wolfenstein II: The New Colossus"],
      ],
      [
        ["360, Windows"],
        [],
        [],
        ["360, Windows"],
        ["OG, Switch"],
        [],
        [],
        [],
        [],
        [],
        ["Windows, VR"],
        [],
        [],
      ]
    ),
    new series(
      "Deathsmiles",
      "True Tyrant",
      [
        ["Deathsmiles I・II"],
        ["Deathsmiles", "Deathsmiles (JP)", "Deathsmiles (EU)"],
        ["Deathsmiles II X"],
      ],
      [[], ["JP, EU"], []]
    ),
    new series(
      "Demon Slayer",
      "Dancer of the Fire God",
      [
        ["Demon Slayer -Kimetsu no Yaiba- The Hinokami Chronicles"],
        ["Demon Slayer -Kimetsu no Yaiba- Sweep the Board!"],
      ],
      [[], []]
    ),
    new series(
      "Deponia",
      "Inspector Trash Vest",
      [
        ["Deponia"],
        ["Chaos On Deponia"],
        ["Goodbye Deponia"],
        ["Deponia Doomsday"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Destiny",
      "Ergo Sum",
      [["Destiny", "Destiny (Xbox 360)"], ["Destiny 2"]],
      [["360"], []]
    ),
    new series(
      "Destroy All Humans!",
      "Cryptosporidium",
      [
        ["Destroy All Humans!", "Destroy All Humans! (Windows)"],
        ["Destroy All Humans! - Clone Carnage"],
        [
          "Destroy All Humans! 2: Reprobed",
          "Destroy All Humans! 2: Reprobed (Xbox One)",
        ],
      ],
      [["Windows"], [], ["Xbox One"]]
    ),
    new series(
      "Deus Ex",
      "Deus ex Machina",
      [["Deus Ex: Human Revolution"], ["Deus Ex: Mankind Divided"]],
      [[], []]
    ),
    new series(
      "Devil May Cry",
      "Danced with the Devil",
      [
        [
          "Devil May Cry HD Collection",
          "Devil May Cry HD Collection (Xbox 360)",
        ],
        [
          "Devil May Cry 4 Special Edition",
          "Devil May Cry 4 Special Edition (Xbox 360)",
        ],
        ["Devil May Cry 5 Special Edition", "Devil May Cry 5"],
        [
          "DmC: Devil May Cry: Defintive Edition",
          "DmC: Devil May Cry: Defintive Edition (Xbox 360)",
        ],
      ],
      [["360"], ["360"], ["OG"], ["360"]]
    ),
    new series(
      "Diablo",
      "Dark Lord of Hellfire",
      [
        ["Diablo II: Resurrected"],
        ["Diablo III: Reaper of Souls - Ultimate Evil Edition"],
        ["Diablo IV"],
      ],
      [[], [], []]
    ),
    new series(
      "DiRT",
      "43 Forever",
      [
        ["DiRT Rally"],
        ["DiRT Rally 2.0", "DiRT Rally 2.0 (Windows)"],
        ["DiRT 4"],
        ["DiRT 5"],
      ],
      [[], ["Windows"], [], []]
    ),
    new series(
      "Disney",
      "Little Blue Wrecking Ball",
      [
        ["Disney Universe"],
        ["The Disney Afternoon Collection"],
        ["Disney Classic Games: Aladdin and The Lion King"],
        ["Rush: A Disney Pixar Adventure"],
        ["Disneyland Adventures"],
        ["Disney Dreamlight Valley"],
        ["Disney Speedstorm"],
        ["Meet the Robinsons"],
        ["Cars: Mater-National Championship"],
        ["Discs of Tron"],
        ["Disney's Bolt"],
        ["Toy Story 3"],
        ["Tron: Evolution", "Tron: Evolution (GFWL)"],
        ["Cars 2: The Video Game"],
        ["Disney Pixar Brave: The Video Game"],
        ["Disney Pixar Toy Story Mania!"],
        ["DuckTales Remastered"],
        ["Cars 3: Driven to Win", "Cars 3: Driven to Win (Xbox 360)"],
        ["LEGO Pirates of the Caribbean: The Video Game"],
        ["LEGO The Incredibles"],
      ],
      [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["GFWL"],
        [],
        [],
        [],
        [],
        ["360"],
        [],
        [],
      ]
    ),
    new series(
      "Don't Starve",
      "The Nightmare King",
      [["Don't Starve: Giant Edition"], ["Don't Starve Together"]],
      [[], []]
    ),
    new series(
      "Doodle",
      "Master of the Worlds",
      [
        ["Doodle Mafia: Crime City"],
        ["Doodle God: Evolution"],
        ["Doodle Devil: 3volution"],
      ],
      [[], [], []]
    ),
    new series(
      "DOOM",
      "The Only Thing They Fear Is Me",
      [
        ["DOOM (1993)"],
        ["DOOM II (Classic)"],
        ["DOOM + DOOM II"],
        ["DOOM 64"],
        ["DOOM 3", "DOOM 3 (Xbox 360)"],
        ["DOOM"],
        ["DOOM Eternal", "DOOM Eternal (Windows)"],
      ],
      [[], [], [], [], ["360"], [], ["Windows"]]
    ),
    new series(
      "DOOM (Collection)",
      "The Icon of Sin",
      [["DOOM + DOOM II"]],
      [[]]
    ),
    new series(
      "DOOM (Guy)",
      "Rip & Tear, Until it is Done",
      [
        ["DOOM (1993)"],
        ["DOOM II (Classic)"],
        ["DOOM 64"],
        ["DOOM 3", "DOOM 3 (Xbox 360)"],
      ],
      [[], [], [], ["360"]]
    ),
    new series(
      "DOOM (Slayer)",
      "Send Unto Them...Only Me",
      [["DOOM"], ["DOOM Eternal", "DOOM Eternal (Windows)"]],
      [[], ["Windows"]]
    ),
    new series(
      "Double Dragon",
      "Way of the Dragon",
      [
        ["DOUBLE DRAGON"],
        ["DOUBLE DRAGON II: The Revenge"],
        ["DOUBLE DRAGON III: The Sacred Stones", "DOUBLE DRAGON 4"],
        ["Super Double Dragon"],
        ["Double Dragon Advance"],
        ["Double Dragon Gaiden: Rise Of The Dragons"],
      ],
      [[], [], [], [], [], [], []]
    ),
    new series(
      "Dragon Age",
      "Dragon's Bane",
      [
        ["Dragon Age: Origins"],
        ["Dragon Age II", "Dragon Age II (JP)"],
        ["Dragon Age: Inquisition"],
        ["Dragon Age: The Veilguard"],
      ],
      [[], ["JP"], [], []]
    ),
    new series(
      "Dragon Ball",
      "Ultra Instinct Mastered",
      [
        ["Dragon Ball XenoVerse", "Dragon Ball XenoVerse (Xbox 360)"],
        ["Dragon Ball XenoVerse 2"],
        ["Dragon Ball FighterZ", "Dragon Ball FighterZ (Windows)"],
        ["Dragon Ball Z: Kakarot"],
        ["Dragon Ball: The Breakers"],
        ["Dragon Ball: Sparking! ZERO"],
      ],
      [["360"], [], ["Windows"], [], [], []]
    ),
    new series(
      "Dragon Quest",
      "Erdick",
      [
        [
          "DRAGON QUEST XI S: Echoes of an ELusive Age",
          "DRAGON QUEST XI S: Echoes of an ELusive Age (JP)",
        ],
        ["DRAGON QUEST Builders 2"],
        ["Infinity Strash: DRAGON QUEST The Adventure of Dai"],
        ["DRAGON QUEST III HD-2D Remake"],
      ],
      [["JP"], [], [], []]
    ),
    new series(
      "Dragon's Dogma",
      "Awakened One",
      [["Dragon's Dogma: Dark Arisen"], ["Dragon's Dogma 2"]],
      [[], []]
    ),
    new series(
      "Draw a Stickman",
      "Awakened One",
      [["Draw a Stickman: EPIC"], ["Draw a Stickman: EPIC 2"]],
      [[], []]
    ),
    new series(
      "DUNE",
      "Kwisatz Haderach",
      [["DUNE: Spice Wars"], ["Dune: Imperium"]],
      [[], []]
    ),
    new series(
      "Dungeons & Dragons",
      "Dungeon Master",
      [
        ["Baldur's Gate: Dark Alliance"],
        ["Baldur's Gate: Dark Alliance II"],
        ["Baldur's Gate and Baldur's Gate II: Enhanced Editions"],
        ["Baldur's Gate 3"],
        ["Idle Champions of the Forgotten Realms"],
        ["Dark Alliance"],
        ["Neverwinter Nights: Enhanced Edition"],
        ["Planescope: Torment and Icewind Dale: Enhanced Editions"],
      ],
      [[], [], [], [], [], [], [], []]
    ),
    new series(
      "Baldur's Gate",
      "Hero of the Forgotten Realms",
      [
        ["Baldur's Gate: Dark Alliance"],
        ["Baldur's Gate: Dark Alliance II"],
        ["Baldur's Gate and Baldur's Gate II: Enhanced Editions"],
        ["Baldur's Gate 3"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Dying Light",
      "The Best Runner in the Tower",
      [["Dying Light"], ["Dying Light 2: Stay Human"]],
      [[], []]
    ),
    new series(
      "Earth Defense Force",
      "Infernal Master Destroyer Deluxe",
      [
        ["Earth Dfense Force 2017"],
        ["Earth Defense Force: Insect Armageddon"],
        ["Earth Defense Force 2025", "Earth Defense Force 2025 (JP)"],
      ],
      [[], [], ["JP"]]
    ),
    new series(
      "Enemy of Humanity",
      "Disarm and Dismember",
      [
        ["Carrion", "Carrion (Windows)"],
        ["Destroy All Humans!", "Destroy All Humans! (Windows)"],
        ["Destroy All Humans!: Clone Carnage"],
        [
          "Destroy All Humans! 2: Reprobed",
          "Destroy All Humans! 2: Reprobed (Xbox One)",
        ],
        ["Maneater"],
        ["Sea Salt"],
        ["Slayaway Camp: Butcher's Cut"],
        ["The Darkness", "The Darkness (JP)", "The Darkness (DE)"],
        ["The Darkness II"],
      ],
      [["Windows"], ["Windows"], [], ["Xbox One"], [], [], [], ["JP, DE"], []]
    ),
    new series(
      "Everyone Is Here",
      "Slayer of Worlds",
      [
        ["Dead Cells", "Dead Cells (Windows)"],
        ["Blasphemous"],
        ["Blasphemous 2"],
        ["Curse of the Dead Gods", "Curse of the Dead Gods (Windows)"],
        ["Guacamelee! 2"],
        ["Hollow Knight: Voidheart Edition"],
        ["Hyper Light Drifter"],
        ["Skul: The Hero Slayer"],
        ["Hotline Miami Collection"],
        ["Hotline Miami"],
        ["Hotline Miami 2: Wrong Number"],
        ["Katana ZERO"],
        ["Risk of Rain"],
        ["Risk of Rain 2"],
        [
          "Shovel Knight: Treasure Trove",
          "Shovel Knight: Treasure Trove (Windows)",
        ],
        ["Slay the Spire"],
        ["Terraria"],
        ["Castlevania Anniversary Collection"],
        ["Castlevania Advance Collection"],
        [
          "Castlevania: Symphony of the Night",
          "Castlevania: Symphony of the Night (JP)",
        ],
        ["Castlevania: Harmony of Despair"],
        ["Castlevania: Lords of Shadow"],
        ["Castlevania: Lords of Shadow - Mirror of Fate HD"],
        ["Castlevania: Lords of Shadow 2"],
      ],
      [
        ["Windows"],
        [],
        [],
        ["Windows"],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["Windows"],
        [],
        [],
        [],
        [],
        ["JP"],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "Fable",
      "Ruler of Albion",
      [
        ["Fable II Pub Games"],
        ["Fable II"],
        ["Fable III"],
        ["Fable III (GFWL)"],
        ["Fable Heroes"],
      ],
      [[], [], ["GFWL"], []]
    ),
    new series(
      "Fall Guys",
      "Golden Guy",
      [["Fall Guys"], ["Stumble Guys"]],
      [[], []]
    ),
    new series(
      "Fallout",
      "Legend of the Wastes",
      [
        ["Fallout 3"],
        ["Fallout New Vegas"],
        ["Fallout 4"],
        ["Fallout 76", "Fallout 76 (Windows)"],
        ["Fallout Shelter"],
      ],
      [[], [], [], ["Windows"], []]
    ),
    new series(
      "Far Cry",
      "Definition of Insanity",
      [
        ["Far Cry Classic"],
        ["Far Cry Instincts Predator"],
        ["Far Cry 3 Classic Edition", "Far Cry 3"],
        ["Far Cry 3 Blood Dragon", "Far Cry 3 Blood Dragon Classic Edition"],
        ["Far Cry 4", "Far Cry 4 (Xbox 360)"],
        ["Far Cry Primal"],
        ["Far Cry 5"],
        ["Far Cry New Dawn"],
        ["Far Cry 6"],
      ],
      [[], [], ["360"], ["360"], ["360"], [], [], [], []]
    ),
    new series(
      "FATAL FRAME",
      "Voice of the Voiceless",
      [
        ["FATAL FRAME: Maiden of Black Water"],
        ["FATAL FRAME: Mask of the Lunar Eclipse"],
      ],
      [[], []]
    ),
    new series(
      "F.E.A.R.",
      "Onry6",
      [
        ["F.E.A.R.: First Encounter Assault Recon"],
        ["F.E.A.R.: Files"],
        ["F.E.A.R. 2: Project Origin"],
        ["F.E.A.R. 3"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Figment",
      "Back at the Surface",
      [["Figment: Journey Into the Mind"], ["Figment 2: Creed Valley"]],
      [[], []]
    ),
    new series(
      "Final Fantasy",
      "Living Legacy",
      [
        ["Starnger of Paradise: Final Fantasy Origin"],
        ["Final Fantasy"],
        ["Final Fantasy II"],
        ["Final Fantasy III"],
        ["Final Fantasy IV"],
        ["Final Fantasy V"],
        ["Final Fantasy VI"],
        ["CRISIS CORE -FINAL FANTASY VII- REUNION"],
        ["Final Fantasy VII", "Final Fantasy VII (Windows)"],
        [
          "Final Fantasy VIII Remastered",
          "Final Fantasy VIII Remastered (Windows)",
        ],
        ["Final Fantasy IX"],
        [
          "Final Fantasy X/X-2 HD Remaster",
          "Final Fantasy X/X-2 HD Remaster (Windows)",
        ],
        [
          "Final Fantasy XII The Zodiac Age",
          "Final Fantasy XII The Zodiac Age (Windows)",
        ],
        ["Final Fantasy XIII", "Final Fantasy XIII (Windows)"],
        ["Final Fantasy XIII-2", "Final Fantasy XIII-2 (Windows)"],
        [
          "Lighting Returns: Final Fantasy XIII",
          "Lighting Returns: Final Fantasy XIII (Windows)",
        ],
        [
          "Final Fantasy Type 0-HD",
          "Final Fantasy Type 0-HD (JP)",
          "Final Fantasy Type 0-HD (Asian)",
          "Final Fantasy Type 0-HD (AU/EU)",
        ],
        ["A King's Tale: Final Fantasy XV"],
        [
          "Final Fantasy XV",
          "Final Fantasy XV Pocket Edition HD",
          "Final Fantasy Pocket Edition (Windows)",
        ],
        ["Final Fantasy XV Multiplayer: Comrades"],
        ["World of Final Fantasy Maxima"],
      ],
      [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["Windows"],
        ["Windows"],
        [],
        ["Windows"],
        ["Windows"],
        ["Windows"],
        ["Windows"],
        ["Windows"],
        ["JP, Asian, AU/EU"],
        [],
        ["HD, Windows"],
        [],
        [],
      ]
    ),
    new series(
      "Final Fantasy I-VI",
      "Lightbring of Legend",
      [
        ["Final Fantasy"],
        ["Final Fantasy II"],
        ["Final Fantasy III"],
        ["Final Fantasy IV"],
        ["Final Fantasy V"],
        ["Final Fantasy VI"],
      ],
      [[], [], [], [], [], []]
    ),
    new series(
      "Final Fantasy VII",
      "SOLIDER of Legend",
      [
        ["CRISIS CORE -FINAL FANTASY VII- REUNION"],
        ["Final Fantasy VII", "Final Fantasy VII (Windows)"],
      ],
      [[], ["Windows"]]
    ),
    new series(
      "Final Fantasy XIII",
      "Warrior Goddess of Legend",
      [
        ["Final Fantasy XIII", "Final Fantasy XIII (Windows)"],
        ["Final Fantasy XIII 2", "Final Fantasy XIII 2 (Windows)"],
        [
          "Lighting Returns: Final Fantasy XIII",
          "Lighting Returns: Final Fantasy XIII (Windows)",
        ],
      ],
      [["Windows"], ["Windows"], ["Windows"]]
    ),
    new series(
      "Final Fantasy XV",
      "True King of Legend",
      [
        ["A King's Tale: Final Fantasy XV"],
        [
          "Final Fantasy XV",
          "Final Fantasy XV Pocket Edition HD",
          "Final Fantasy Pocket Edition (Windows)",
        ],
        ["Final Fantasy XV Multiplayer: Comrades"],
      ],
      [[], ["HD", "Windows"], []]
    ),
    new series(
      "Five Nights at Freddy's",
      "Help Wanted",
      [
        ["Five Nights at Freddy's"],
        ["Five Nights at Freddy's 2"],
        ["Five Nights at Freddy's 3"],
        ["Five Nights at Freddy's 4"],
        ["Five Nights at Freddy's: Sister Location"],
        ["Five Nights at Freddy's: Help Wanted"],
        ["Five Nights at Freddy's: Help Wanted 2"],
        ["Five Nights at Freddy's: Security Breach"],
        ["Five Nights at Freddy's: Into the Pit"],
        ["Freddy Fazber's Pizzeria Simulator"],
        ["Ulimtate Custom Night"],
      ],
      [[], [], [], [], [], [], [], [], [], [], []]
    ),
    new series("Fortnite", "The Looper", [["Fortnite"]], [[]]),
    new series(
      "Forza",
      "All Your Race Are Belong To Us",
      [["Forza Horizon 5"], ["Forza Motorsport"]],
      [[], []]
    ),
    new series(
      "Friday the 13th",
      "The Masked Killer",
      [["Friday the 13th: The Game"], ["Friday the 13th: Killer Puzzle"]],
      [[], []]
    ),
    new series(
      "Galaga",
      "Demon of Death",
      [
        ["Galaga"],
        ["Galaga Legions"],
        ["Galaga Legions DX"],
        ["ARCADE GAME SERIES: GALAGA"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Gears of War",
      "Seriously Inconceivable",
      [
        [
          "Gears of War",
          "Gears of War: Ulimate Edition",
          "Gears of War (Windows)",
        ],
        ["Gears of War 4"],
        ["Gears of War 5"],
        ["Gears Tactics"],
      ],
      [["Ulimate, Windows"], [], [], []]
    ),
    new series(
      "Gems of War",
      "My Kingdom Smells of Rich Mahogany",
      [["Gems of War"]],
      [[]]
    ),
    new series(
      "Geometry Wars",
      "Geometry Warrior",
      [
        ["Geometry Wars: Retro Evolved"],
        ["Geometry Wars: Retro Evolved²"],
        [
          "Geometry Wars³: Dimensions Evolved",
          "Geometry Wars³: Dimensions Evolved (Xbox 360)",
        ],
      ],
      [[], [], ["Xbox 360"]]
    ),
    new series(
      "Ghostbusters",
      "Who You Gonna Call?",
      [
        ["Ghostbusters: Sanctum of Slime"],
        ["Ghostbusters"],
        [
          "Ghostbusters: The Video Game",
          "Ghostbusters: The Video Game Remastered",
        ],
        ["Ghostbusters: Spirits Unleashed"],
      ],
      [[], [], ["360"], []]
    ),
    new series(
      "Ghostrunner",
      "Monolith of Humanity",
      [["Ghostrunner"], ["Ghostrunner 2"]],
      [[], []]
    ),
    new series(
      "Goats 'n' Stuff",
      "G.O.A.T.",
      [
        [
          "Goat Simulator",
          "Goat Simulator (Xbox 360)",
          "Goat Simulator (Windows)",
        ],
        ["Goat Simulator Remastered"],
        ["Goat Simulator 3", "Goat Simulator 3 (Xbox One)"],
        ["DEEEER Simulator: Your Average Everyday Deer Game"],
        ["Catlateral Damage: Remeowstered"],
        ["Maneater"],
        ["Octodad"],
        ["SkateBIRD"],
        ["United Goose Game"],
      ],
      [[], ["Xbox 360, Windows"], [], ["Xbox One"], [], [], [], [], [], []]
    ),
    new series(
      "Goat Simulator",
      "I Am Goat",
      [
        [
          "Goat Simulator",
          "Goat Simulator (Xbox 360)",
          "Goat Simulator (Windows)",
        ],
        ["Goat Simulator Remastered"],
        ["Goat Simulator 3", "Goat Simulator 3 (Xbox One)"],
      ],
      [[], ["Xbox 360, Windows"], [], ["Xbox One"]]
    ),
    new series(
      "Grand Theft Auto",
      "Criminal Mastermind",
      [
        ["Grand Theft Auto III"],
        ["Grand Theft Auto: Vice City"],
        ["Grand Theft Auto: San Andreas"],
        ["Grand Theft Auto IV", "Grand Theft Auto IV (GFWL)"],
        [
          "Grand Theft Auto V",
          "Grand Theft Auto V (Xbox One)",
          "Grand Theft Auto V (JP) (Xbox One)",
        ],
      ],
      [[], [], [], ["GFWL"], ["Xbox One, Xbox One JP"]]
    ),
    new series(
      "Guacamelee!",
      "The Chosen Juan",
      [["Guacamelee! Super Turbo Championship Edition"], ["Guacamelee! 2"]],
      [[], []]
    ),
    new series(
      "Gulity Gear + BlazBlue",
      "Messiah Will Not Come",
      [
        ["Guilty Gear -Strive-"],
        [
          "BlazBlue: Chrono Phantasma EXTEND",
          "BlazBlue: Cross Tag Battle Special Edition",
        ],
      ],
      [[], [], []]
    ),
    new series(
      "Gundam",
      "White Shooting Star",
      [["SD Gundam Battle Alliance"], ["Gundam Evolution"]],
      [[], []]
    ),
    new series(
      "Gungeon",
      "Gungeon Master",
      [["Enter the Gungeon"], ["Exit the Gungeon"]],
      [[], []]
    ),
    new series(
      "Gunvolt",
      "The Muse of Hope",
      [
        ["Azure Striker GUNVOLT"],
        ["Azure Striker GUNVOLT 2"],
        ["Azure Striker GUNVOLT 3"],
        ["Gunvolt Chronicles: Luminous Avenger iX"],
        ["Gunvolt Chronicles: Luminous Avenger iX 2"],
        ["GUNVOLT RECORDS: Cychronicle"],
      ],
      [[], [], [], [], [], []]
    ),
    new series("Hades", "God of Blood", [["Hades"], ["Hades 2"]], [[], []]),
    new series(
      "Halo",
      "Hyper-Lethal Vector",
      [
        ["Halo Wars", "Halo Wars: Definitive Edition"],
        ["Halo: The Master Chief Collection"],
        ["Halo: Spartan Assault", "Halo: Spartan Assault (Windows)"],
        ["Halo 5: Guardians"],
        ["Halo Wars 2"],
        ["Halo Infinite"],
      ],
      [["360"], [], ["Windows"], [], [], []]
    ),
    new series(
      "Bungie Saga",
      "Inheritor",
      [["Halo: The Master Chief Collection"]],
      [[]]
    ),
    new series(
      "Reclaimer Saga",
      "Reclaimer",
      [
        ["Halo: The Master Chief Collection"],
        ["Halo 5: Guardians"],
        ["Halo Infinite"],
      ],
      [[], [], []]
    ),
    new series(
      "Halo Wars",
      "Forerunner",
      [["Halo Wars", "Halo Wars: Definitive Edition"], ["Halo Wars 2"]],
      [["360"], []]
    ),
    new series(
      "Harvest Moon",
      "Agriculture, I Am Thy King",
      [
        ["Harvest Moon: Mad Dash"],
        ["Harvest Moon: Light of Hope Special Edition Complete"],
        ["Harvest Moon: One World"],
        ["Harvest Moon: The Winds of Anthos"],
        [
          "Story of Seasons: Friends of Mineral Town",
          "Story of Seasons: Friends of Mineral Town (Windows)",
        ],
        ["Story of Seasons: A Wonderful Life"],
      ],
      [[], [], [], [], ["Windows"], []]
    ),
    new series(
      "Hatsune Miku",
      "The First Sound of the Future",
      [
        ["Hatsune Miku Logic Paint 5"],
        ["Hatsune Miku Jugsaw Puzzle"],
        ["Hatsune Miku: The Planet Of Wonder And Fragment Of Wishes"],
      ],
      [[], [], []]
    ),
    new series("HAWKED", "HAWKED", [["HAWKED"]], [[]]),
    new series(
      "Hazelight Studios",
      "It Took Two",
      [
        [
          "Brothers: A Tale of Two Sons",
          "Brothers: A Tale of Two Sons Remake",
          "Brothers: A Tale of Two Sons (Xbox 360)",
        ],
        ["A Way Out"],
        ["It Takes Two"],
      ],
      [["Remake, Xbox 360"], [], []]
    ),
    new series(
      "Hellblade",
      "Goddess of Curses",
      [
        [
          "Hellblade: Senua's Sacrifice",
          "Hellblade: Senua's Sacrifice (Windows)",
        ],
        ["Senua's Saga: Hellblade II"],
      ],
      [["Windows"], []]
    ),
    new series(
      "Hello Neighbor",
      "No More Fears",
      [
        ["Hello Neighbor"],
        ["Hello Neighbor: Hide & Seek"],
        ["Secret Neighbor"],
        ["Hello Neighbor 2"],
        ["Hello Engineer"],
      ],
      [[], [], [], [], []]
    ),
    new series(
      "Hexic",
      "Grand Pearl Pooh-Bah",
      [["Hexic HD"], ["Hexic 2"]],
      [[], []]
    ),
    new series(
      "Hitman",
      "Silent Assassin",
      [
        ["Hitman HD Pack"],
        ["Hitman: Blood Money HD", "Hitman: Blood Money"],
        ["Hitman: Absolution HD", "Hitman: Absolution"],
        ["Hitman: World of Assassination"],
      ],
      [[], ["360"], ["360"], []]
    ),
    new series(
      "Hot Wheels",
      "World's Best Driver",
      [
        ["Hot Wheels Unleashed"],
        ["Hot Wheels Unleashed 2 - Turbocharged"],
        ["Hot Wheels Monster Trucks: Stunt Mayhem"],
      ],
      [[], [], []]
    ),
    new series(
      "Hotline Miami",
      "1-800-GODDAMN",
      [
        ["Hotline Miami Collection"],
        ["Hotline Miami"],
        ["Hotline Miami 2: Wrong Number"],
      ],
      [[], [], []]
    ),
    new series(
      "House Flipper",
      "(╯°□°)╯︵ ƎSՈOH",
      [["House Flipper"], ["House Flipper 2"]],
      [[], []]
    ),
    new series(
      "How to Survive",
      "Searched and Dismembered",
      [
        ["How to Survive: Storm Warning Edition"],
        ["How to Survive 2"],
        ["Welcome to ParadiZe"],
      ],
      [[], [], []]
    ),
    new series(
      "Hyakki Yagyō",
      "Onmyōji",
      [
        ["Corpse Party"],
        ["FATAL FRAME: Maiden of Black Water"],
        ["FATAL FRAME: Mask of the Lunar Eclipse"],
        ["Ghostwire: Tokyo"],
        ["Ikai"],
      ],
      [[], [], [], [], []]
    ),
    new series(
      "Indiana Jones",
      "The Man With Too Many Names",
      [
        ["Indiana Jones and the Great Circle"],
        ["LEGO Indiana Jones: Original Adventures"],
        ["LEGO Indiana Jones 2: The Adventure Continues"],
      ],
      [[], [], []]
    ),
    new series(
      "Jackbox Games",
      "Don't Know Jack",
      [
        ["Drawful 2"],
        ["Fibbage"],
        ["Quiplash"],
        ["The Jackbox Party Pack", "The Jackbox Party Pack (Xbox 360)"],
        ["The Jackbox Party Pack 2"],
        ["The Jackbox Party Pack 3"],
        ["The Jackbox Party Pack 4"],
        ["The Jackbox Party Pack 5"],
        ["The Jackbox Party Pack 6"],
        ["The Jackbox Party Pack 7"],
        ["The Jackbox Party Pack "],
        ["The Jackbox Party Pack 9"],
        ["The Jackbox Party Pack 10"],
        ["The Jackbox Party Starter"],
      ],
      [[], [], ["360"], [], [], [], [], [], [], [], [], [], []]
    ),
    new series(
      "Jurassic Park",
      "All I Got Was This T-Shirt",
      [
        ["Jurassic Park: The Game"],
        ["Jurassic World Evolution"],
        ["Jurassic World Evolution 2"],
        ["Jurassic Park Classic Games Collection"],
        ["LEGO Jurassic World", "LEGO Jurassic World (Xbox 360)"],
      ],
      [[], [], [], [], ["360"]]
    ),
    new series(
      "Jurassic World Evolution",
      "Life Finds A Way",
      [["Jurassic Park Evolution"], ["Jurassic Park Evolution 2"]],
      [[], []]
    ),
    new series(
      "Just Cause",
      "Rico Was Here",
      [
        ["Just Cause"],
        ["Just Cause 2", "Just Cause 2 (JP)", "Just Cause 3", "Just Cause 4"],
      ],
      [[], ["JP"], [], []]
    ),
    new series(
      "Katamari",
      "I <3 Katamari",
      [
        ["Beautiful Katamari"],
        ["Katamari Damacy Reroll", "Katamari Damacy Reroll (Windows)"],
        ["We Love Katamari REROLL+ Royal Reverie"],
      ],
      [[], ["Windows"], []]
    ),
    new series(
      "Killer Instinct",
      "Natural Born Killer",
      [
        ["Killer Instinct Classic"],
        ["Killer Instinct 2 Classic"],
        ["Killer Instinct"],
      ],
      [[], [], []]
    ),
    new series(
      "Kingdom Hearts",
      "Heart of Light",
      [
        [
          "KINGDOM HEARTS - HD 1.5+2.5 ReMIX",
          "KINGDOM HEARTS - HD 1.5+2.5 ReMIX (JP)",
        ],
        [
          "KINGDOM HEARTS HD 2.8 Final Chapter Prologue",
          "KINGDOM HEARTS HD 2.8 Final Chapter Prologue (JP)",
        ],
        [
          "KINGDOM HEARTS III",
          "KINGDOM HEARTS III (JP)",
          "KINGDOM HEARTS III (Asian)",
        ],
        [
          "KINGDOM HEARTS Melody of Memory",
          "KINGDOM HEARTS Melody of Memory (JP)",
          "KINGDOM HEARTS Melody of Memory (Asian)",
        ],
      ],
      [["JP"], ["JP"], ["JP, Asian"], ["JP, Asian"]]
    ),
    new series(
      "Kingdom Rush",
      "Supreme Defender",
      [["Kingdom Rush"], ["Kingdom Rush Frontiers"], ["Kingdom Rush Origins"]],
      [[], [], []]
    ),
    new series(
      "Kona",
      "Le Survivant",
      [["Kona"], ["Kona II: Brume"]],
      [[], [], []]
    ),
    new series(
      "Kunio-kin",
      "Guardian of Nekkestsu High",
      [
        ["Stay Cool, Kobayashi-san!: A River City Ransom Story"],
        ["River City Ransom"],
        ["River City Girls", "River City Girls (Windows)"],
        ["River City Girls Zero"],
        ["River City Girls 2"],
      ],
      [[], [], ["Windows"], [], []]
    ),
    new series(
      "Layers of Fear",
      "Let Insanity Run",
      [["Layers of Fear (2016)"], ["Layers of Fear 2"], ["Layers of Fear"]],
      [[], [], []]
    ),
    new series(
      "League of Legends",
      "Legendary",
      [
        ["Ruined King: A League of Legends Story"],
        ["The Mageseeker: A League of Legends Story"],
        ["CONVERGENCE: A League of Legends Story"],
        ["Song of Nunu: A League of Legends Story"],
      ],
      [[], [], [], []]
    ),
    new series(
      "Left 4 Dead",
      "Zombicidal Maniac",
      [["Left 4 Dead"], ["Left 4 Dead 2"]],
      [[], []]
    ),
    new series(
      "LEGO",
      "Master Builder",
      [
        ["LEGO Star Wars II: The Original Trilogy"],
        ["LEGO Star Wars: The Complete Saga"],
        ["LEGO Indiana Jones: Original Adventures"],
        ["LEGO Batman", "LEGO Batman (JP)"],
        ["LEGO Indiana Jones 2: The Adventure Continues"],
        ["LEGO Star Wars III: The Clone Wars"],
        ["LEGO Pirates of the Caribbean"],
        ["LEGO Batman 2: DC Super Heroes"],
        ["LEGO The Lord of the Rings"],
        ["LEGO Marvel Super Heroes", "LEGO Marvel Super Heroes (Xbox 360)"],
        ["The LEGO Movie Videogame", "The LEGO Movie Videogame (Xbox 360)"],
        ["LEGO The Hobbit", "LEGO The Hobbit (Xbox 360)"],
        [
          "LEGO Batman 3: Beyond Gotham",
          "LEGO Batman 3: Beyond Gotham (Xbox 360)",
        ],
        ["LEGO Jurassic World", "LEGO Jurassic World (Xbox 360)"],
        ["LEGO Dimensions", "LEGO Dimensions (Xbox 360)"],
        ["LEGO Marvel's Avengers", "LEGO Marvel's Avengers (Xbox 360)"],
        [
          "LEGO Star Wars: The Force Awakens",
          "LEGO Star Wars: The Force Awakens (Xbox 360)",
        ],
        ["LEGO Worlds"],
        ["LEGO CITY Undercover"],
        ["The LEGO NINJAGO Movie Video Game"],
        ["LEGO Marvel Super Heroes 2"],
        ["LEGO The Incredibles"],
        ["LEGO DC Super-Villains"],
        [
          "LEGO Harry Potter Collection",
          "LEGO Harry Potter Collection (Xbox One)",
        ],
        ["LEGO Harry Potter: Years 1-4"],
        ["LEGO Harry Potter: Years 5-7"],
        ["The LEGO Movie 2 Videogame"],
        ["LEGO Builder's Journey"],
        ["LEGO Star Wars: The Skywalker Saga"],
        ["LEGO Brawls"],
        ["LEGO Bricktales"],
        ["LEGO 2k Drive"],
      ],
      [
        [],
        [],
        [],
        ["JP"],
        [],
        [],
        [],
        [],
        [],
        ["360"],
        ["360"],
        ["360"],
        ["360"],
        ["360"],
        ["360"],
        ["360"],
        ["360"],
        [],
        [],
        [],
        [],
        [],
        [],
        ["Xbox One"],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ]
    ),
    new series(
      "LEGO DC Comics",
      "Bat's All Folks",
      [
        ["LEGO Batman", "LEGO Batman (JP)"],
        ["LEGO Batman 2: DC Super Heroes"],
        [
          "LEGO Batman 3: Beyond Gotham",
          "LEGO Batman 3: Beyond Gotham (Xbox 360)",
        ],
        ["LEGO DC Super Villians"],
      ],
      [["JP"], [], ["360"], []]
    ),
    new series(
      "LEGO Disney",
      "That Was Totally Wicked!",
      [["LEGO Pirates of the Caribbean"], ["LEGO The Incredibles"]],
      [[], []]
    ),
    new series(
      "LEGO Harry Potter",
      "I'm a What?",
      [
        [
          "LEGO Harry Potter Collection",
          "LEGO Harry Potter Collection (Xbox One)",
        ],
        ["LEGO Harry Potter: Years 1-4"],
        ["LEGO Harry Potter: Years 5-7"],
      ],
      [[], ["Xbox One"], []]
    ),
    new series(
      "LEGO Indiana Jones",
      "Don't Call Me Junior",
      [
        ["LEGO Indiana Jones: Original Adventures"],
        ["LEGO Indiana Jones 2: The Adventure Continues"],
      ],
      [[], []]
    ),
    new series(
      "LEGO Marvel",
      "Avengers Assembled",
      [
        ["LEGO Marvel Super Heroes", "LEGO Marvel Super Heroes (Xbox 360)"],
        ["LEGO Marvel Avengers", "LEGO Marvel Avengers (Xbox 360)"],
        ["LEGO Marvel Super Heroes 2"],
      ],
      [["360"], ["360"], []]
    ),
    new series(
      "LEGO Middle-earth",
      "Worth Greater Than the Shire",
      [
        ["LEGO The Lord of the Rings"],
        ["LEGO The Hobbit", "LEGO The Hobbit (Xbox 360)"],
      ],
      [[], ["360"]]
    ),
    new series(
      "LEGO Movie",
      "Everything is Awesome!",
      [
        ["The LEGO Movie Videogame", "The LEGO Movie Videogame (Xbox 360)"],
        ["The LEGO Movie Videogame 2"],
      ],
      [["360"], []]
    ),
    new series(
      "LEGO Star Wars",
      "Everything is Awesome!",
      [
        ["LEGO Star Wars II: The Original Trilogy"],
        ["LEGO Star Wars: The Complete Saga"],
        ["LEGO Star Wars III: The Clone Wars"],
        [
          "LEGO Star Wars: The Force Awakens",
          "LEGO Star Wars: The Force Awakens (Xbox 360)",
        ],
        ["LEGO Star Wars: The Skywalker Saga"],
      ],
      [[], [], []["360"], []]
    ),
    new series(
      "Lethal League",
      "Ace Player",
      [["Lethal League"], ["Lethal League Blaze"]],
      [[], []]
    ),
    new series(
      "Life is Strange",
      "Quintessential Hipster",
      [
        [
          "Life is Strange Remastered",
          "Life is Strange Remastered (Xbox One)",
          "Life is Strange Remastered (Xbox 360)",
        ],
        [
          "Life is Strange: Before the Storm Remastered",
          "Life is Strange: Before the Storm Remastered (Xbox One)",
        ],
        ["Life is Strange 2"],
        ["Life is Strange: True Colors"],
        ["Life is Strange: Double Exposure"],
      ],
      [["Xbox One, Xbox 360"], ["Xbox 360"], [], [], []]
    ),
    new series(
      "LIMBO + INSIDE",
      "Limboloi Boi",
      [["LIMBO", "LIMBO (Xbox 360)", "LIMBO (Windows)"], ["INSIDE"]],
      [["Xbox 360, Windows"], []]
    ),
    new series(
      "Little Nightmares",
      "Little Lost Thing",
      [["Little Nightmares"], ["Little Nightmares 2"]],
      [[], []]
    ),
    new series(
      "Little Tail Bronx",
      "Shepherd of the Taranis",
      [["Fuga: Melodies of Steel"], ["Fuga: Melodies of Steel 2"]],
      [[], []]
    ),
    new series(
      "Lords of the Fallen",
      "I Kill Dead People",
      [["Lords of the Fallen (2014)"], ["Lords of the Fallen"]],
      [[], []]
    ),
    new series(
      "Lovebirds",
      "Never Let Go",
      [
        ["Gone Home"],
        ["Haven"],
        ["It Takes Two"],
        [
          "Life is Strange: Before the Storm Remastered",
          "Life is Strange: Before the Storm Remastered (Xbox One)",
        ],
        ["Lovers in a Dangerous Spacetime"],
        ["Maquette"],
        ["Stardew Valley", "Stardew Valley (Windows)"],
      ],
      [[], [], [], ["Xbox One"], [], [], ["Windows"]]
    ),
    new series(
      "Lovecraftian Horror",
      "Havester of Sorrow",
      [
        ["Call of Cthulhu"],
        ["Call of the Sea", "Call of the Sea (Windows)"],
        ["Darkwood"],
        ["Dredge"],
        ["Forgive Me Father"],
        ["Outer Wilds"],
        ["Sea Salt"],
        ["Sherlock Holmes: The Awakened"],
        ["SOMA"],
        ["Source of Madness"],
        ["Sunless Sea"],
        ["The Sinking Sea"],
      ],
      [[], ["Windows"], [], []]
    ),
    new series(
      "Lumines",
      "Black Belt Champion",
      [["Lumines Live!"], ["Lumines Remastered"]],
      [[], []]
    ),
    new series(
      "Mafia",
      "Capo Dei Copi",
      [
        ["Mafia: Defintive Edition", "Mafia: Defintive Edition (Windows)"],
        [
          "Mafia II: Defintive Edition",
          "Mafia II",
          "Mafia II (JP)",
          "Mafia II (EEU)",
        ],
        ["Mafia III: Definitive Edition"],
      ],
      [["Windows"], ["360, JP, EEU"], []]
    ),
    new series(
      "Mana",
      "Seiken Densetsu",
      [["Trials of Mana"], ["Legend of Mana"], ["Visions of Mana"]],
      [[], [], []]
    ),
    new series(
      "Mara (Deiland)",
      "Keeper of Mara",
      [["Trials of Mana"], ["Legend of Mana"], ["Visions of Mana"]],
      [[], [], []]
    ),
    new series(
      "Marvel Studios",
      "The Amazing Spider-Man",
      [
        ["The Amazing Spider-Man 2", "The Amazing Spider-Man 2 (Xbox 360)"],
        ["Deadpool", "Deadpool (Xbox 360)"],
        ["Marvel Avengers"],
        ["Marvel's Guardians of the Galaxy"],
        ["Marvels' Midnight Suns", "Marvels' Midnight Suns (Xbox One)"],
        ["Ultimate Marvel vs. Capcom 3"],
        ["Marvel vs Capcom: Infinite"],
        ["Marvel's Guardians of the Galaxy: The Telltale Series"],
        ["LEGO Marvel Superheroes", "LEGO Marvel Superheroes (Xbox 360)"],
        ["LEGO Marvel Superheroes 2"],
        ["LEGO Marvel Avengers", "LEGO Marvel Avengers (Xbox 360)"],
      ],
      [["360"], ["360"], [], [], ["Xbox One"], [], [], [], ["360"], [], ["360"]]
    ),
    new series(
      "Mass Effect",
      "SPECTRE",
      [
        ["Mass Effect Legendary Edition"],
        ["Mass Effect"],
        ["Mass Effect 2"],
        ["Mass Effect 3"],
        ["Mass Effect: Andromeda"],
      ],
      [[], [], ["JP"], [], []]
    ),
    new series(
      "Mega Man",
      "The Blue Bomber",
      [
        ["Mega Man Legacy Collection"],
        ["Mega Man Legacy Collection 2"],
        ["Mega Man 11"],
        ["Mega Man X Legacy Collection"],
        ["Mega Man X Legacy Collection 2"],
        ["Mega Man Zero/ZX Legacy Collection"],
      ],
      [[], [], [], [], [], []]
    ),
    new series(
      "Mega Man Classic",
      "Rockman",
      [
        ["Mega Man Legacy Collection"],
        ["Mega Man Legacy Collection 2"],
        ["Mega Man 11"],
      ],
      [[], [], []]
    ),
    new series(
      "Mega Man X",
      "Limitless Possibility",
      [["Mega Man Legacy Collection"], ["Mega Man Legacy Collection 2"]],
      [[], []]
    ),
    new series(
      "Metal Gear",
      "Snake Eater",
      [
        ["Metal Gear & Metal Gear 2: Solid Snake"],
        ["Metal Gear Solid - MCV"],
        ["Metal Gear Solid 2: Sons of Liberty - MCV"],
        ["Metal Gear Solid 3: Snake Eater - MCV"],
        ["Metal Gear Solid: Peace Walker HD"],
        ["Metal Gear Rising: Revengeance"],
        [
          "Metal Gear Soid V: Ground Zeros",
          "Metal Gear Solid V: Ground Zeros (Xbox 360)",
        ],
        [
          "Metal Gear Solid V: Phantom Pain",
          "Metal Gear Solid V: Phantom Pain (Xbox 360)",
        ],
        ["Metal Gear Survive"],
      ],
      [[], [], [], [], [], [], ["360"], ["360"], []]
    ),
    new series(
      "Metro",
      "Harcore Ranger",
      [
        ["Metro Redux"],
        [
          "Metro 2033",
          "Metro 2033 (JP)",
          "Metro 2033 Redux",
          "Metro 2033 Redux (Windows)",
        ],
        [
          "Metro: Last Light",
          "Metro: Last Light (JP)",
          "Metro: Last Light Redux",
          "Metro: Last Light Redux (Windows)",
        ],
        [
          "Metro Exodus",
          "Metro Exodus (Windows)",
          "Metro Exodus Enhanced Edition (Windows)",
        ],
      ],
      [
        ["JP, Redux, Redux (Windows)"],
        ["JP, Redux, Redux (Windows)"],
        ["Windows, Enhanced Edition (Windows)"],
      ]
    ),
    new series("XDefiant", "Advanced Warfighter", [["XDefiant"]], [[]]),
    new series(
      "Tom Clancy's The Division",
      "Objectively Experienced",
      [["Tom Clancy's The Division"], ["Tom Clancy's The Division 2"]],
      [[], []]
    ),
    // new series("", "", []),
  ];
}
