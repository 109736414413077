import Nav from '../../components/Nav/Nav';
import NoRoute from '../../components/NoRoute/NoRoute';

export default function PageNotFound() {
  return (
    <>
      <Nav />
      <NoRoute />
    </>
  );
}
