import seriesProgress from "../../classes/seriesProgress";
import Series from "../Series/Series";
import "./SeriesList.css";

interface iSeriesList {
  seriesProgress: seriesProgress[];
}

export default function SeriesList({ seriesProgress }: iSeriesList) {
  const calculateCompleted = () => {
    let completedSeries = 0;

    for (let k = 0; k < seriesProgress.length; k++) {
      let gamesProgress = seriesProgress[k].gamesProgress;
      let total = 0;

      for (let i = 0; i < gamesProgress.length; i++) {
        if (gamesProgress[i].completionStatus) {
          total++;
        }
      }

      if (total == gamesProgress.length) {
        completedSeries++;
      }
    }

    return completedSeries;
  };

  return (
    <div className="series-list">
      <div className="series-completed-total">
        Series Completed: {calculateCompleted()}
      </div>
      <hr />
      {seriesProgress.map((series) => (
        <Series
          seriesTitle={series.title}
          seriesFlavortext={series.flavortext}
          gamesProgress={series.gamesProgress}
          platformStackInfo={series.platformStackInfo}
        />
      ))}
    </div>
  );
}
